import Base from "@//utils/base";

export default class Top {
  private _moreNewsBtn: HTMLElement | null;
  private _moreNewsContents: HTMLElement | null;

  constructor() {
    // class変数
    this._moreNewsBtn = Base._d.querySelector(".c-news__more");
    this._moreNewsContents = Base._d.querySelector(".c-news__list.-more");

    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    this._moreNewsBtn?.addEventListener("click", this.accordionNews);
  }

  /**
   * ニュースのアコーディオン開閉
   */
  public accordionNews = () => {
    // console.log(this._moreNewsContents);
    // console.log(this._moreNewsBtn);
    if (this._moreNewsContents) {
      this._moreNewsContents.classList.add("is-show");
    }
    if (this._moreNewsBtn) {
      this._moreNewsBtn.style.display = "none";
    }
  };
}

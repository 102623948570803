import ClassName from "@//conf/className";
import EventName from "@//conf/eventName";
import Base from "@//utils/base";

export default class Menu {
  private _top: HTMLElement | null;
  private _header: HTMLElement | null;
  private _menuBtn: HTMLElement | null;
  private BREAK_POINT_SP: number;
  constructor() {
    // class変数
    this._top = Base._d.getElementById("data-top");
    this._header = Base._d.getElementById("data-header");
    this._menuBtn = Base._d.getElementById("data-menu-btn");
    this.BREAK_POINT_SP = 750;
    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    this.addEvent();
    if (this._top) {
      Base._w.onscroll = () => {
        this.checkScrollPos();
      };
      this.checkScrollPos();
    }
  }
  private addEvent = () => {
    // 以下共通で追加するEVENT
    if (this._menuBtn) {
      this._menuBtn.addEventListener(EventName.CLICK, this.toggleMenu);
    }
  };

  // private removeEvent(): void {
  //   // 以下共通で削除するEVENT
  // }
  private toggleMenu = () => {
    // 以下共通で追加するEVENT
    if (this._header && !Base.hasClass(this._header, ClassName.OPEN)) {
      Base.addClass(this._header, ClassName.OPEN);
    } else if (this._header) {
      Base.removeClass(this._header, ClassName.OPEN);
    }
  };
  private checkScrollPos = () => {
    const _concept: HTMLElement | null = Base._d.getElementById("concept");
    const _conceptPosY: number = _concept ? Base._w.pageYOffset + _concept.getBoundingClientRect().top : 0;
    if (_conceptPosY < Base._w.scrollY) {
      if (this._header) {
        Base.addClass(this._header, ClassName.VISIBLE);
      }
    } else {
      if (this._header) {
        Base.removeClass(this._header, ClassName.VISIBLE);
        if (Base._w.innerWidth > this.BREAK_POINT_SP) {
          Base.removeClass(this._header, ClassName.OPEN);
        }
      }
    }
  };
}

export default class EventName {
  public static LOAD = "load";
  public static CLICK = "click";
  public static RESIZE = "resize";
  public static MOUSE_MOVE = "mousemove";
  public static DOM_MOUSE_SCROLL = "DOMMouseScroll";
  public static ON_MOUSE_WHEEL = "onmousewheel";
  public static SCROLL = "scroll";
  public static TOUCH_START = "touchstart";
  public static TOUCH_MOVE = "touchmove";
  public static TOUCH_END = "touchend";
  public static ORIENTATION_CHANGE = "orientationchange";
}

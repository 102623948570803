import Base from "@//utils/base";

export default class Accordion {
  public _accordion: NodeListOf<HTMLElement> | null;
  public _accordionBtn: NodeListOf<HTMLButtonElement> | null;
  constructor() {
    // class変数
    this._accordion = Base._d.querySelectorAll(".c-accordion__item");
    this._accordionBtn = Base._d.querySelectorAll(".c-accordion__title");
    this.init();
  }

  public init = () => {
    // console.log(this._accordion);

    // ボタンがクリックされた時
    this._accordionBtn?.forEach((element) => {
      element.addEventListener("click", this.accordionToggle);
    });
  };

  /**
   * アコーディオンの開閉を制御する関数
   * @param event - イベントオブジェクト
   */
  public accordionToggle = (event: Event) => {
    const target = event.currentTarget as HTMLElement;
    if (target.parentNode instanceof HTMLElement) {
      target.parentNode.classList.toggle("is-show");
    }
  };
}

import Base from "@//utils/base";

export default class sessionField {
  private _sessionBtn: NodeListOf<HTMLAnchorElement> | null;

  constructor() {
    // class変数
    this._sessionBtn = Base._d.querySelectorAll(".c-session__more");

    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    this._sessionBtn?.forEach((btn) => {
      btn.addEventListener("click", this.accordionSession);
    });
  }

  /**
   * アコーディオンセッションを制御する関数です。
   * @param event - マウスイベント
   */
  public accordionSession = (event: MouseEvent) => {
    const target = event.currentTarget as HTMLElement;
    // ボタンを押した箇所のコンテンツ
    const targetContents = target?.previousSibling?.previousSibling as HTMLElement;

    targetContents?.classList.toggle("is-show");

    if (targetContents?.classList.contains("is-show")) {
      target.innerHTML = "閉じる";
    } else {
      target.innerHTML = "詳しく見る";
    }
  };
}

import Accordion from "@//module/accordion";
import Menu from "@//module/menu";
import Modal from "@//module/modal";
import SessionField from "@//pages/sessionField";
import Sponsor from "@//pages/sponsor";
import Top from "@//pages/top";
import Base from "@//utils/base";

class AppClass {
  private _top: HTMLElement | null;
  private _sessionField: HTMLElement | null;
  private _sponsor: HTMLElement | null;
  private _a: HTMLCollectionOf<HTMLAnchorElement>;
  private _header: HTMLElement | null;
  constructor() {
    // class変数
    this._top = Base._d.getElementById("index");
    this._sessionField = Base._d.getElementById("field");
    this._sponsor = Base._d.getElementById("sponsor");
    this._a = Base._d.getElementsByTagName("a");
    this._header = Base._d.getElementById("data-header");
    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    if (this._top) {
      new Top();
    } else if (this._sessionField) {
      new SessionField();
    } else if (this._sponsor) {
      new Sponsor();
    }
    new Menu();
    new Modal();
    new Accordion();
  }
}
window.onload = () => {
  new AppClass();
};

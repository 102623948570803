import Base from "@//utils/base";

export default class Sponsor {
  private _tabLink: NodeListOf<HTMLAnchorElement> | null;
  private _tabBtn: NodeListOf<HTMLInputElement> | null;

  constructor() {
    // class変数
    this._tabLink = Base._d.querySelectorAll("#p-sponsor .c-link.-anchor");
    this._tabBtn = Base._d.querySelectorAll("input[name='tab-item']");

    this.init();
  }
  public init(): void {
    // 以下共通で追加するEVENT
    this._tabLink?.forEach((btn) => {
      btn.addEventListener("click", this.tabSwitch);
    });

    // console.log(this._tabBtn);
    // console.log(this._tabLink);
  }

  /**
   * タブ切り替え
   * @param event - マウスイベント
   */
  public tabSwitch = (event: MouseEvent) => {
    const target = event.currentTarget as HTMLElement | null;

    this._tabBtn?.forEach((btn) => {
      if (target?.getAttribute("tab") === btn?.getAttribute("value")) {
        btn.checked = true;
        console.log("せやで");
      } else {
        console.log("ちゃうで");
      }
    });
  };
}

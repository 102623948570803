import Base from "@//utils/base";

export default class Modal {
  private _bodyEl: HTMLCollectionOf<HTMLBodyElement> | null;
  public _modal: HTMLElement | null;
  private _modalBtn: NodeListOf<HTMLButtonElement> | null;
  private _modalBg: HTMLElement | null;
  private _video: HTMLVideoElement | null;
  constructor() {
    // class変数
    this._bodyEl = Base._d.getElementsByTagName("body");
    this._modal = Base._d.getElementById("modal");
    this._modalBtn = Base._d.querySelectorAll(".js-modalBtn");
    this._modalBg = Base._d.querySelector(".modal_bg");
    this._video = Base._d.querySelector(".video video");
    this.init();
  }

  public init = () => {
    // console.log(this._modal);

    // ボタンがクリックされた時
    this._modalBtn?.forEach((element) => {
      // const test : string = string(element.className);
      element.addEventListener("click", this.modalOpen);
    });
    // 背景がクリックされた時
    this._modalBg?.addEventListener("click", this.modalClose);
  };

  /**
   * モーダルを開く関数
   * @param event - イベントオブジェクト
   */
  public modalOpen = (event: Event) => {
    const modalId: string | null | undefined = (event.target as HTMLElement).getAttribute("data-id");
    const modalTarget: string | null | undefined = (event.target as HTMLElement).getAttribute("data-target");

    if (!(modalId === undefined || modalId === null) && !(modalTarget === undefined || modalTarget === null)) {
      switch (modalTarget) {
        case "news":
          this._modal?.classList.add("is-show");
          Base._d.querySelectorAll(".modal_inner").forEach((element) => {
            element.classList.remove("is-show");
          });
          Base._d.querySelector(".modal_" + modalId)?.classList.add("is-show");
          break;

        case "movie":
          this._modal?.classList.add("is-show");
          Base._d.querySelectorAll(".modal_inner").forEach((element) => {
            element.classList.remove("is-show");
          });
          Base._d.querySelector(".movie_" + modalId)?.classList.add("is-show");
          break;

        default:
          console.log("モーダルがありません");
          break;
      }
    }
    // console.log(this._bodyEl.style);
    if (this._bodyEl) {
      this._bodyEl[0].style.overflow = "hidden";
    }
  };

  /**
   * モーダルを閉じる関数
   */
  public modalClose = () => {
    this._modal?.classList.remove("is-show");
    this._modal?.removeAttribute("class");
    this._modal?.classList.add("modal");
    if (this._bodyEl) {
      this._bodyEl[0].style.overflow = "auto";
    }
    this._video?.pause();
  };
}
